import React, { createContext, useState } from 'react';
import { UserContextSettings, UserContextValue } from '../types';

// User context

export const defaultSettings: UserContextSettings = {
  loggedIn: false,
  refreshToken: '',
  accessToken: '',
};

export const UserContext = createContext<UserContextValue>({
  ...defaultSettings,
  saveSettings: () => {},
});

export const UserProvider: React.FC<{
  children: React.ReactNode;
  // settings: UserContextSettings;
}> = ({ children }) => {
  const [currentSettings, setCurrentSettings] =
    useState<UserContextSettings>(defaultSettings);

  const saveSettings = (settings: UserContextSettings) => {
    setCurrentSettings(settings);
  };

  return (
    <UserContext.Provider value={{ ...currentSettings, saveSettings }}>
      {children}
    </UserContext.Provider>
  );
};

export const SettingsConsumer = UserContext.Consumer;
