// https://elazizi.com/posts/react-query-auth-token-refresh/#simple-project-setup
import type { AxiosError } from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';

import { api } from '../client';

import { ReviewReq, ReviewRequestedRes, ReviewRes } from '../types/';
import { enqueueSnackbar } from 'notistack';

const getRequestedReviews = () => {
  return api.get<AxiosError, ReviewRequestedRes>('/v1/review/requested');
};

const postReview = (values: ReviewReq) => {
  return api.post<AxiosError, ReviewRes>(`/v1/review`, values);
};

export const reviewService = () => {
  const requestedReviews = useQuery({
    queryKey: ['requestedReviews'],
    queryFn: getRequestedReviews,
  });

  const reviewsMutation = useMutation({
    mutationFn: postReview,
    onError: ({ message }) => {
      enqueueSnackbar(`requestedReviews error: ${message}`, {
        variant: 'error',
      });
    },
    onSuccess: () => {},
  });

  return {
    requestedReviewsList: requestedReviews.data?.data.reviews,
    requestedReviewsRefetch: requestedReviews.refetch,
    requestedReviewsListIsLoading: requestedReviews.isLoading,
    reviewResponse: reviewsMutation.data?.data,
    reviewIsPending: reviewsMutation.isPending,
    sendReview: reviewsMutation.mutateAsync,
  };
};
