// https://elazizi.com/posts/react-query-auth-token-refresh/#simple-project-setup
import type { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';

import { api } from '../client';
import { useSnackbar } from 'notistack';
import { UserSmeRes } from '../types/';

const getSmeUsers = () => {
  return api.get<AxiosError, UserSmeRes>('/v1/user/sme');
};

export const userService = () => {
  const { enqueueSnackbar } = useSnackbar();

  const smeUsersList = useQuery({
    queryKey: ['smeUsersList'],
    queryFn: getSmeUsers,
  });

  // TODO: add error handling
  // if (smeUsersList.error && smeUsersList.errorUpdateCount === 1) {
  //   enqueueSnackbar(`getSmeUsers error: ${smeUsersList.error.message}`, {
  //     variant: 'error',
  //   });
  // }

  return {
    smeUsersList: smeUsersList.data?.data.smes,
  };
};
