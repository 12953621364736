import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import { authService } from '../api';
import { useEffect } from 'react';
import { UserContext } from '../context/userContext';

export const Login = () => {
  const { login, logout } = authService();
  const [open, setOpen] = React.useState(false);
  const [user, setUser] = React.useState('jure.mav@gmail.com');
  const [password, setPassword] = React.useState('');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { loggedIn, refreshToken, accessToken } = React.useContext(UserContext);

  useEffect(() => {
    if (loggedIn) {
      handleClose();
    }
  }, [loggedIn]);

  const handleLogin = () => {
    login({ user, password });
  };
  const handleLogout = () => {
    logout({ refresh_token: refreshToken });
  };
  const handleCopy = () => {
    window.prompt('Copy to clipboard: Ctrl+C, Enter', accessToken);
  };

  const hideLogin = !(user && password);

  return (
    <>
      <Stack spacing={2} direction="row">
        {!loggedIn && (
          <Button onClick={handleOpen} variant="contained">
            Log In
          </Button>
        )}
        {loggedIn && (
          <Button onClick={handleLogout} variant="contained">
            Log Out
          </Button>
        )}
      </Stack>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Sign in</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Welcome user, please sign in to continue
          </DialogContentText>
          <Stack spacing={2}>
            <TextField
              value={user}
              onChange={(e) => setUser(e.target.value)}
              id="user"
              label="User name"
              variant="standard"
            />
            <TextField
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              id="password"
              label="Password"
              variant="standard"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCopy} autoFocus>
            Copy HTTPBearer
          </Button>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
          <Button
            disabled={hideLogin}
            variant="contained"
            onClick={handleLogin}
          >
            Login
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
