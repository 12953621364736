import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Login } from './components/Login';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Conversations } from './components/Conversations';
import { AppBar, CssBaseline, Toolbar } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import { SideMenu } from './components/SideMenu';
import { SnackbarProvider } from 'notistack';

// Create a client
const queryClient = new QueryClient();

export default function App() {
  const drawerWidth = 240;
  return (
    <SnackbarProvider maxSnack={5}>
      <QueryClientProvider client={queryClient}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
            }}
          >
            <Toolbar sx={{ justifyContent: 'space-between' }}>
              <Typography variant="h6" noWrap component="div">
                Safety Advisor Chatbot
              </Typography>

              <Login />
            </Toolbar>
          </AppBar>
          {/* <DashboardLayoutBasic /> */}

          <SideMenu />
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Toolbar />

            <Routes>
              <Route path="/" element="Home" />
              <Route
                path="conversation/:conversationId"
                element={<Conversations />}
              />
            </Routes>
          </Box>
        </Box>
      </QueryClientProvider>
    </SnackbarProvider>
  );
}
