import * as React from 'react';
import { UserContext } from '../context/userContext';
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { ConversationMenu } from './ConversationMenu';
import { Link } from 'react-router-dom';
import { ReviewsMenu } from './ReviesMenu';

export const SideMenu: React.FC = () => {
  const drawerWidth = 240;

  const menu = [
    {
      name: 'Home',
      url: '/',
      icon: HomeIcon,
    },
    // {
    //   name: 'My conversations',
    //   url: '/conversation/12',
    //   icon: ChatIcon,
    // },
  ];

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar />
      <Divider />

      <List>
        {menu?.map(({ name, url, icon }) => (
          <ListItem key={url} component={Link} to="/" disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <HomeIcon />
                {/* {icon as unknown as React.ReactElement} */}
              </ListItemIcon>
              <ListItemText primary={name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <ConversationMenu />
      <ReviewsMenu />
    </Drawer>
  );
};
