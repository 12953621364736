import * as React from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Stack,
} from '@mui/material';
import { userService, reviewService } from '../api';
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
  useForm,
} from 'react-hook-form-mui';

interface FormInputs {
  comment: string;
  smeId: number;
}
interface Props {
  qaUid: string;
}

export const ContactSme: React.FC<Props> = ({ qaUid }) => {
  const [isFormExpanded, setIsFormExpanded] = React.useState(false);

  const formContext = useForm<FormInputs>({
    defaultValues: {
      comment: '',
    },
  });

  const { handleSubmit, reset, clearErrors } = formContext;

  const { smeUsersList } = userService();
  const { sendReview, requestedReviewsRefetch } = reviewService();

  const smeSelectOoptions = smeUsersList?.map(
    ({ first_name, last_name, id }) => ({
      id,
      label: `${first_name} ${last_name}`,
    }),
  );

  const handleSubmitReview = ({ comment, smeId }: FormInputs) => {
    sendReview({
      qa_uid: qaUid,
      requester_note: comment,
      reviewer_id: smeId,
    }).then(() => {
      requestedReviewsRefetch();
    });
  };

  const handleExpand = () => {
    setIsFormExpanded(true);
  };

  const handleCollapse = () => {
    setIsFormExpanded(false);
    clearErrors();
  };

  return (
    <>
      <Collapse in={!isFormExpanded} timeout="auto" unmountOnExit>
        <Button onClick={handleExpand} variant="contained">
          Contact SME
        </Button>
      </Collapse>

      <FormContainer
        formContext={formContext}
        handleSubmit={handleSubmit(handleSubmitReview)}
      >
        <Collapse in={isFormExpanded} timeout="auto" unmountOnExit>
          <Card elevation={2} sx={{ maxWidth: '50%' }}>
            <CardHeader title="Contact SME" />

            <CardContent>
              <Stack spacing={2}>
                <SelectElement
                  name="smeId"
                  label="SMEs by domain"
                  fullWidth
                  options={smeSelectOoptions}
                  required
                />

                <TextFieldElement
                  name="comment"
                  label="Comment"
                  fullWidth
                  required
                  rows={4}
                  about=""
                  multiline
                ></TextFieldElement>
              </Stack>
            </CardContent>
            <CardActions>
              <Button onClick={handleCollapse} size="small">
                Close
              </Button>
              <Button
                variant="contained"
                // onClick={handleContactSme}
                size="small"
                type="submit"
              >
                Contact SME
              </Button>
            </CardActions>
          </Card>
        </Collapse>
      </FormContainer>
    </>
  );
};
