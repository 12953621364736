import * as React from 'react';
import Box from '@mui/material/Box';
import { UserContext } from '../context/userContext';
import {
  Button,
  Card,
  CardContent,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  OutlinedInput,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { conversationService } from '../api';
import ReactMarkdown from 'react-markdown';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { ConversationItemQas } from '../types';
import { References } from './References';
import { ContactSme } from './ContactSme';

export const Conversations: React.FC = () => {
  const { loggedIn } = React.useContext(UserContext);
  const listRef = useRef<HTMLSpanElement>(null);

  const { conversationId } = useParams();

  if (!conversationId) {
    return null;
  }

  const {
    getConversationQas,
    sendQuestion,
    conversationQas,
    conversationQasIsPending,
    questionResponse,
    questionIsPending,
  } = conversationService();

  const [question, setQuestion] = React.useState('');
  const [currentSessionQuestions, setCurrentSessionQuestions] = React.useState<
    ConversationItemQas[]
  >([]);

  useEffect(() => {
    getConversationQas({ uid: conversationId });
    // Clear state
    setCurrentSessionQuestions([]);
  }, [conversationId]);

  useEffect(() => {
    if (questionResponse && currentSessionQuestions) {
      setCurrentSessionQuestions([
        ...currentSessionQuestions,
        questionResponse,
      ]);
    }
  }, [questionResponse]);

  // After question returned
  useEffect(() => {
    listRef.current?.lastElementChild?.scrollIntoView({
      behavior: 'smooth',
    });
    setQuestion('');
  }, [conversationQas, currentSessionQuestions]);

  const handleSubmitQuestion = (question: string) => () => {
    sendQuestion({ uid: conversationId, prompt: question });
  };

  const handleFollowUpQuestion = (question: string) => () => {
    setQuestion(question);
    listRef.current?.lastElementChild?.scrollIntoView({
      behavior: 'smooth',
    });
    sendQuestion({ uid: conversationId, prompt: question });
  };

  const allAnswers = [
    ...(currentSessionQuestions || []),
    ...(conversationQas || []),
  ].reverse(); // Sorting

  let isFollowUp = false;

  if (conversationQasIsPending) {
    return <LinearProgress />;
  }

  return loggedIn ? (
    <>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Stack spacing={2}>
          <Typography sx={{ marginBottom: 2 }} ref={listRef}>
            {allAnswers?.map(({ answer, question, uid, source_documents }) => (
              // TODO: add https://mui.com/material-ui/react-card/
              <>
                <ReactMarkdown
                  key={uid}
                  components={{
                    // @ts-ignore
                    strong: (el, ...props) => {
                      if (
                        (el.children?.toString() || '').includes(
                          'Follow up question',
                        )
                      ) {
                        isFollowUp = true;
                      }

                      // return React.cloneElement(el.node);

                      return <strong>{el.children}</strong>;
                    },
                    // @ts-ignore
                    h1: (el) => {
                      isFollowUp = false;

                      return (
                        // <Chip icon={<FaceIcon />} label="With Icon" variant="outlined" />
                        // <h2>
                        //   <SmartToyIcon color="secondary" /> {el.children}
                        // </h2>

                        <Card sx={{ backgroundColor: '#e9e9e9' }}>
                          <CardContent sx={{ display: 'flex' }}>
                            <SmartToyIcon
                              sx={{ marginRight: '8px' }}
                              color="secondary"
                            />
                            <Typography gutterBottom variant="subtitle1">
                              {el.children}
                            </Typography>
                          </CardContent>
                        </Card>
                      );
                    },
                    li: ({ children }) => {
                      let content = <li>{children}</li>;
                      if (isFollowUp) {
                        content = (
                          <>
                            <Button
                              onClick={handleFollowUpQuestion(
                                children?.toString() || '',
                              )}
                              variant="outlined"
                            >
                              {children}
                            </Button>
                            {'\n'}
                          </>
                        );
                      }
                      return content;
                    },
                  }}
                >{`# ${question}\n ${answer}`}</ReactMarkdown>
                <References sourceDocuments={source_documents} />
                <ContactSme qaUid={uid} />
              </>
            ))}
          </Typography>

          {questionIsPending && <LinearProgress />}
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="question">Question</InputLabel>
            <OutlinedInput
              id="question"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleSubmitQuestion(question)}
                    edge="end"
                  >
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
        </Stack>
      </Box>
    </>
  ) : null;
};
