import * as React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SourceDocument } from '../types/';
import ReactMarkdown from 'react-markdown';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

export const References: React.FC<{ sourceDocuments: SourceDocument[] }> = ({
  sourceDocuments,
}) => {
  if (!sourceDocuments) {
    return null;
  }

  return (
    <>
      {sourceDocuments.map(({ page, page_content, source }, i) => {
        return (
          <Accordion key={i} disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Stack direction="row" spacing={2}>
                <PictureAsPdfIcon fontSize="small" color="action" />
                <p>{source}</p>
                <strong>page {page}</strong>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <ReactMarkdown>{page_content}</ReactMarkdown>
            </AccordionDetails>
          </Accordion>
        );
      })}
      <br />
    </>
  );
};
