import * as React from 'react';
import { UserContext } from '../context/userContext';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  Collapse,
} from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';

import { conversationService } from '../api';
import { Link } from 'react-router-dom';

export const ConversationMenu: React.FC = () => {
  const { loggedIn } = React.useContext(UserContext);
  const [open, setOpen] = React.useState(true);

  const { conversationList, conversationQasIsLoading } = conversationService();

  if (conversationQasIsLoading) {
    return <CircularProgress />;
  }

  const handleClick = () => {
    setOpen(!open);
  };

  return loggedIn ? (
    <List>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <ChatIcon />
        </ListItemIcon>
        <ListItemText primary="My Reviews" />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {conversationList?.map(({ name, uid }) => (
            <ListItem
              component={Link}
              to={`/conversation/${uid}`}
              key={uid}
              disablePadding
            >
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <ArtTrackIcon />
                </ListItemIcon>
                <ListItemText primary={name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </List>
  ) : (
    <>'Login please, to get the list of Questions'</>
  );
};
